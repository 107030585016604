import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from './Utiilities/ImageSlider';
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import Lightbox from './Utiilities/Lightbox';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import ScrollToTop from './Utiilities/ScrolltoTop';
import G2Badge from './Utiilities/G2Badge';
import TabsWithImage from './Utiilities/TabsWithImage';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import Integration from './Utiilities/Integration';
import { MyContext } from '../App';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
// import { mouseflow} from "react-mouseflow";

function ED365() {
   
    
        const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
        React.useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 767);
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
    }, []);
  

    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: "http://localhost:3000/employee-directory-365",
            title: "ED365"
        }
    });

    const AppName = React.useContext(MyContext);
    const dynamicImages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos1_RPf_AeXggA.png?updatedAt=1700627745162',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos2_fZX_JD70Nu.png?updatedAt=1700627744112',
    ];
    const accordionItems = [
        {
            title: `How to create an employee directory in SharePoint?`, content: (
                <ul>
                    To create an employee directory in SharePoint:
                    <li>
                        <b> Open Your SharePoint Site:</b> Open your SharePoint site where you want to create the directory.
                    </li>
                    <li>
                        <b>Create a List:</b> Click + New  List and name it "Employee Directory."
                    </li>
                    <li>
                        <b> Add Details:</b> Add columns for Name, Job Title, Department, Phone, Email, etc.
                    </li>
                    <li>
                        <b> Fill the Directory:</b> Enter employee details or import them from Excel.
                    </li>
                    <li>
                        <b> Display the Directory:</b> Add the list to a page using the List Web Part and customize the view.
                    </li>
                    Your SharePoint employee directory is now live and ready to use!
                </ul>
            )
        },
        {
            title: `Does Microsoft have an employee directory?`, content: (
                <ul>
                    Microsoft 365 includes tools for an employee directory:
                    <li>
                        Microsoft Teams: Search for colleagues by name or department.
                    </li>
                    <li> Outlook: Use the Global Address List for contact details.
                    </li>
                    <li>
                        Delve: Explore detailed profiles, org charts, and shared documents.
                    </li>
                    <li>
                        Easy-to-use interface.
                    </li>
                    <li>
                        Advanced search options.
                    </li>
                    <li>
                        Seamless integration with Microsoft 365.
                    </li>
                    This makes managing and finding employees simple and efficient.
                </ul>


            )
        },
        {
            title: `How to find SharePoint directory?`, content: (
                <ul>
                    To find a directory in SharePoint, such as an employee or document directory, follow these steps:
                    <li>
                        <b> Go to Your SharePoint Site: </b>Open the SharePoint site where the directory is stored.
                    </li>
                    <li>
                        <b> Search in Site Contents: </b> <ul>
                            <li>Click the Settings gear icon (top-right corner) and select Site Contents.</li>
                            <li>
                                Look for a list or library named after the directory (e.g., "Employee Directory").
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Use the Search Bar:</b>
                        <ul>
                            <li> Use the Search bar at the top of the SharePoint page.</li>
                            <li> Enter keywords like "directory" or "employee directory" to locate it quickly.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Check for Links or Pages:</b>  Some directories might be displayed on a custom page or as a link in the site’s navigation menu.
                    </li>
                    <li>
                        <b> Ask the Admin:</b> If you can’t find the directory, your SharePoint administrator can provide access or guidance.
                    </li>
                    This should help you locate the directory you need!
                </ul>


            )
        },
        {
            title: `How to find SharePoint directory?`, content: (
                <ul>
                    To find a directory in SharePoint, such as an employee or document directory, follow these steps:
                    <li>
                        <b> Go to Your SharePoint Site: </b>Open the SharePoint site where the directory is stored.
                    </li>
                    <li>
                        <b> Search in Site Contents: </b> <ul>
                            <li>Click the Settings gear icon (top-right corner) and select Site Contents.</li>
                            <li>
                                Look for a list or library named after the directory (e.g., "Employee Directory").
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Use the Search Bar:</b>
                        <ul>
                            <li> Use the Search bar at the top of the SharePoint page.</li>
                            <li> Enter keywords like "directory" or "employee directory" to locate it quickly.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Check for Links or Pages:</b>  Some directories might be displayed on a custom page or as a link in the site’s navigation menu.
                    </li>
                    <li>
                        <b> Ask the Admin:</b> If you can’t find the directory, your SharePoint administrator can provide access or guidance.
                    </li>
                    This should help you locate the directory you need!
                </ul>


            )
        },
        {
            title: `What is employee directory?`, content: (
                <ul>
                    A SharePoint employee directory 365 is a central hub where you can find information about everyone in your organization.
                    <li>
                        <b>Basic Information:</b> Names, job titles, departments, and contact details (phone, email, etc.).
                    </li>
                    <li>
                        <b> Organizational Structure:</b> Reporting hierarchy and team associations.
                    </li>
                    <li>
                        <b> Profiles: </b> Additional details like photos, work locations, and expertise.
                    </li>
                </ul>


            )
        },


    ];
    const Edimages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Seamless-integration_-9GbPXuMO.png?updatedAt=1705384671710',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Real-time-sync_RvMctcJTU.png?updatedAt=1705383933936',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Advance-search-filter_THl4Y7SS0.jpg?updatedAt=1705384438699',
    ];
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['50 Users', '2 Support tickets per year', 'Free updates via Microsoft store', 'Alphabet filtering', '1 Exclude options ', '1 Search options', 'Mobile responsiveness', 'Auto sync data from M365'],
        },
        {
            name: 'Plus',
            price: '$99',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['Upto 150 users ', '4 support tickets per year', '3 Exclude options ', '3 Search options ', 'Choice to hide user properties', 'Configure SIP / Call protocols', 'Multiple profile image sync', 'Add as web part in Intranet'],
        },
        {
            name: 'Premium',
            price: '$149',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['Upto 200 users ', 'Add-on $50 for every 100 users', 'Unlimited support', 'Free updates with support', '5 Exclude options', '5 Search options ', 'Custom Fields', 'Sync Active directory attributes', 'Birthday and anniversary notifications', 'Custom logo', 'Organization chart', 'Custom quick link', 'Custom return page link', 'Available in 10+ languages', 'Modern SharePoint App', 'Advance top bars filters'],
        },
        {
            name: 'Enterprise',
            price: '$249',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['Upto 300 users ', 'Add-on $50 for every 100 users', 'Custom column Search​', 'Filters - About me, Projects, Skills', 'Filters - School and Hobbies', 'Quicklink to custom profile pages', 'Executive Assistant relationship', 'Online presence with extended status', 'Calendar - free / busy information', 'Profile Imaged with text frames', 'Import users using CSV', 'QR Code ​', 'Exclude Bulk Shared Mailboxes​', 'Hide Mobile number selectively​', 'Guest Users ​', 'Dashboard', 'Non Microsoft 365 users​', 'Dedicated account manager', 'One free customization*'],
        },
    ];
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');
    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };
    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const tabs = [
        {
            id: 1, title: 'Integration', Heading: 'Employee Directory Software with Microsoft 365 Integration',
            content: (
                <ul>
                    <li>
                        Enhance organizational synergy with SharePoint Employee Directory 365.
                    </li>
                    <li>
                        Seamlessly integrated with Microsoft 365, it transforms your employee directory into a central hub in SharePoint and Teams.
                    </li>
                    <li>
                        Access crucial employee information instantly, minimizing platform-switching and boosting productivity.
                    </li>
                    <li>
                        Automatic updates ensure employee information is always current and accurate across all platforms.
                    </li>
                    <li>
                        Customizable search and filtering options make it easy to locate and connect with colleagues efficiently.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Integration_J10FfNdpZB.gif?updatedAt=1720500232501'
        },
        {
            id: 2, title: 'Search Capabilities', Heading: ' Enhanced Search Capabilities: Microsoft Employee Directory', content: (
                <ul>
                    <li>
                        The Employee Directory provides powerful search and filtering, enabling quick location of colleagues by name, department, skills, and custom attributes.
                    </li>
                    <li>
                        In large organizations, these advanced search functions streamline navigation, enhancing collaboration and efficiency.
                    </li>
                    <li>
                        Saved search preferences and recent queries allow for faster access to frequently used filters.
                    </li>
                    <li>
                        Integration with organizational charts visually represents reporting structures for better understanding of team dynamics.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Searching_bszxfaq2js.gif?updatedAt=1720500232508'
        },
        {
            id: 3, title: 'Visualization', Heading: 'Interactive Organizational Visualizations', content: (
                <ul>
                    <li>
                        The SharePoint Staff Directory includes dynamic organizational charts that visually clarify company hierarchy and reporting lines.
                    </li>
                    <li>
                        These interactive charts help team members understand roles and assist managers in optimizing team structures, enhancing collaboration and strategic planning.
                    </li>
                    <li>
                        Customizable chart views allow users to focus on specific departments or teams for targeted insights.
                    </li>
                    <li>
                        Real-time updates ensure that organizational charts reflect the most current structure and personnel changes.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/visualization_viJFIn6Wt.gif?updatedAt=1720500231624'
        },
        {
            id: 4, title: 'Data Synchronization', Heading: 'Real-Time Data Synchronization: Employee Directory Software', content: (
                <ul>
                    <li>
                        The Employee Directory Application ensures accurate and consistent employee data with real-time synchronization from Microsoft 365.
                    </li>
                    <li>
                        This dynamic integration keeps records up-to-date and reliable, providing an efficient framework for managing organizational data.
                    </li>
                    <li>
                        Instant updates on employee information reflect changes across all integrated Microsoft 365 applications.
                    </li>
                    <li>
                        Automated alerts notify users of significant updates or changes to employee records for prompt action.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/dataSync__gvNUPNCn.gif?updatedAt=1720500232251'
        },
        {
            id: 5, title: 'Security', Heading: 'Enhanced Confidentiality and Security: Employee Directory 365 ', content: (
                <ul>
                    <li>
                        Employee Directory 365 safeguards sensitive employee data with advanced security measures and strict controls within the Microsoft 365 framework.
                    </li>
                    <li>
                        It ensures data security, protects against unauthorized access, and maintains compliance within your organization.
                    </li>
                    <li>
                        Granular access controls allow administrators to define permissions and restrict data visibility based on user roles.
                    </li>
                    <li>
                        Regular security audits and compliance checks ensure ongoing protection and adherence to industry standards.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Security%20(1)_9wJphPYCa.gif?updatedAt=1720500232285'
        },
    ];
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Employee Directory for Microsoft 365 | Simplify Connections, Enhance Collaboration</title>
                    <meta name="description" content="Employee Directory 365 is a Microsoft 365 Certified app, reviewed by Microsoft for industry standards in security, compliance, and data protection." />
                </Helmet>
                <ScrollToTop />
                <Header />
                <div className='Margin-top'>
            {isMobile ? (
                <img 
                    src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/offers/Mobile.webp?updatedAt=1734109577056" 
                    alt="Christmas Offer - Mobile" 
                />
            ) : (
                <img 
                    src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/offers/christmas_DtKMV_1H9.webp?updatedAt=1734099734880" 
                    alt="Christmas Offer - Desktop" 
                />
            )}
        </div>
                {/* <div className='HR_panel'>
                    <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >

                        <div className='HR_Section1LeftSide '>
                            <h1 className='HR_primaryclr'> Discover the Heartbeat of Your Organization with Employee Directory 365</h1>
                            
                            <p className='HR_textclr'>Looking for a simple way to find and connect with your team? Our easy-to-use Employee Directory Software is built to save time and boost collaboration – all within your Microsoft 365 environment!</p>
                           
                        </div>
                        <div className='HR_Section1RightSide'>
                            <img alt='MainImage' src={"https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/ED365_sAcd5DKMg.png?updatedAt=1727764057869"} loading="lazy" />
                        </div>
                    </div>
                </div> */}
                <div>
                    <G2Badge
                        heading="Now Employee Directory 365 App is Available on Microsoft Teams"
                        imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/MicrosoftTeams-image_En8P7Cj9x4.png?updatedAt=1725515030789" />
                </div>
                <div id="features" className="IdChanges"></div>
                <div>
                    <div  >
                        <TabsWithImage tabs={tabs} />
                    </div>
                </div>
                <div>
                    <Availability />
                </div>
                <div id="client" className="IdChanges"></div>
                <div className='HR_whitesection'>
                    <h2 className='HR_heading HR_MT' >Our Clients
                    </h2>
                    <div className='HR_MT'>
                        <OurClients />
                    </div>
                </div>
                <div id="integration" className="IdChangesIntegration"></div>
                <div className='integartionPT'>
                    <Integration appName={"Employee Directory 365"} />
                </div>
                <div className="IdChangesIntegration"></div>
                <div className='HR_whitesection'>
                    <h2 className='HR_heading HR_MT' id="pricing">Employee Directory 365 Plans
                    </h2>
                    <div className='HR_MT'>
                        <div>
                            <Plans plans={planData} />
                        </div>
                    </div>
                    <SpecailButtons userCount={"1000+"} />
                    <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                    </h3>
                    <div className='Hr_flex HR_MT'>
                        <DemoButtons />
                    </div>
                </div>
                <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
                <div id="faq" className="IdChangesIntegration"></div>
                <div className='HR_FAQ' >

                    <div>
                        <h3 className='HR_faq'>Frequently Asked Questions</h3>
                        <Accordion items={accordionItems} />
                    </div>


                </div>
                <Footer />
            </HelmetProvider> </>
    )

}
export default ED365;